import craftAction from "../utils/craft-action"
import isDev from "../utils/is-dev"

class DownloadPoll {
  constructor(el) {
    if (!el) {
      return
    }

    this._jobId = null

    // Check this often
    this.timeoutDuration = 500

    // After this amount of time, start checking less frequently
    this._timeoutBackoff = 30000

    this._timeoutMax = this._timeoutBackoff * 4 // Api2Pdf allows at least 90s
    this._timeoutBackoffCounter = 0
    this.el = el

    this._modeAuto = this.el.getAttribute('data-download-poll') === 'auto'

    this._formCheck = el.querySelector('form[data-download-poll-form-check]')
    this._formCheckJobInput = el.querySelector('form[data-download-poll-form-check] input[name=jobId]')
    this._formStart = el.querySelector('form[data-download-poll-form-start]')
    this._uiLoading = el.querySelector('[data-download-poll-loading]')
    this._uiError = el.querySelector('[data-download-poll-error]')
    this._uiErrorMessage = el.querySelector('[data-download-poll-error-message]')
    this._uiStart = el.querySelector('[data-download-poll-start]')
    
    if (this._uiStart) {
      this._uiStart.querySelector('[data-icon-label]')
    }

    this._check = this._check.bind(this)
    this._initUiStart = this._initUiStart.bind(this)
    this._cleanupUiStart = this._cleanupUiStart.bind(this)
    this._handleSubmitStart = this._handleSubmitStart.bind(this)
    this._handleSuccessCallback = this._handleSuccessCallback.bind(this)
    this._handleErrorCallback = this._handleErrorCallback.bind(this)

    // Poll automatically if the loading UI is shown by default and the start UI is hidden
    if (!this._formStart || this._modeAuto) {

      // Show loading icon
      this._showUiLoading()

      // Check automatically
      this._check()
    } else {
      // Click to check
      this._initUiStart()

      this._hideUiLoading()
    }
  }

  _handleSubmitStart(e) {
    e.preventDefault()

    // Show loading icon optimistically
    this._showUiLoading()
    this._hideUiStart()

    craftAction(this._formStart, window.csrfTokenValue, (json) => {
      if (json) {
        if (json.jobId) {
          this._jobId = json.jobId              
        }
      }

      this._timeout = setTimeout(this._check, this.timeoutDuration)
    }, (err) => {
      // This is probably an error that couldn’t even start the job, and we don’t want the details in the message.
      return this._handleErrorCallback({ message: `An error occured.` })
    })
  }

  _initUiStart() {
    this._formStart.addEventListener('submit', this._handleSubmitStart)
  }

  _cleanupUiStart() {
    this._formStart.removeEventListener('submit', this._handleSubmitStart)
  }

  _check() {
    this._hideUiDone()

    // Update counter, outside of waiting for response
    this._timeoutBackoffCounter = this._timeoutBackoffCounter + this.timeoutDuration

    // Add the job ID to the form
    if (this._jobId && this._formCheckJobInput) {
      this._formCheckJobInput.setAttribute('value', this._jobId)
    }

    craftAction(this._formCheck, window.csrfTokenValue, this._handleSuccessCallback, this._handleErrorCallback)
  }

  /**
   * Open the URL via a download attribute. This allows the download feature to work on Mobile
   * Safari, and avoids the download being treated like a pop-up.
   * 
   * @todo https://github.com/kennethormandy/cygnuscloud-backend/issues/54
   * @private
   * @param {string} url 
   * @returns {void}
   */
  _openUrl(url) {
    if (!url) {
      return
    }

    let el = document.createElement('a')
    el.href = url
    el.download = 'download'
    document.body.append(el)
    el.click()
    el.remove()
  }

  _handleSuccessCallback(json) {
    if (json.ready) {
      clearTimeout(this._timeout)

      // Also open this URL in another tab, since they have already hit “download”
      this._openUrl(json.asset.url)

      // Hide loading
      this._hideUiLoading()

      // Show done
      this._showUiDone()
    } else {
      // if (isDev) {
      // }

      // After this time, start decreasing the check frequency
      if (this._timeoutBackoffCounter >= this._timeoutBackoff) {
        this.timeoutDuration = Math.ceil(this.timeoutDuration * 1.1)
      }

      if (this._timeoutMax > this._timeoutBackoffCounter) {
        this._timeout = setTimeout(this._check, this.timeoutDuration)
      } else {
        this._showUiError({ message: 'Compiling your download timed out.' });
      }
    }
  }

  _handleErrorCallback(err) {
    this._showUiError(err)
  }

  _hideUiStart() {
    if (this._uiStart) {
      this._cleanupUiStart()
      this._uiStart.style.display = 'none'
      this._uiStart.setAttribute('hidden', true)  
    }
  }

  _showUiStart() {
    if (this._uiStart) {
      this._initUiStart()
      this._uiStart.style.display = 'block'
      this._uiStart.setAttribute('hidden', false)
    }
  }

  _hideUiDone() {
    this._hideUiStart()
  }

  _showUiDone() {
    this._showUiStart()
  }

  _hideUiLoading() {
    this._uiLoading.style.display = 'none'
    this._uiLoading.setAttribute('hidden', true)
  }

  _showUiLoading() {
    this._uiLoading.style.display = 'inline-block'
    this._uiLoading.setAttribute('hidden', false)

    // Keep it at full opacity, even when disabled
    this._uiLoading.style.opacity = 1

    // Disable the button
    this._uiLoading.setAttribute('disabled', 'disabled')
  }

  _hideUiError() {
    this._uiError.innerHTML = ''
    this._uiError.style.display = 'none'
    this._uiError.setAttribute('hidden', true)
  }

  _showUiError(err) {
    let defaultErrorMessage = `Unable to compile your download${this._jobId ? ` (${this._jobId})` : ''}.`
    let msg = typeof err.message !== 'undefined' ? err.message : defaultErrorMessage

    this._uiErrorMessage.innerHTML = msg

    this._uiError.style.display = 'block'
    this._uiError.setAttribute('hidden', false)

    this._hideUiLoading()
    this._showUiStart()

    // Disable the download button
    if (this._uiStart) {
      this._uiStart.setAttribute('disabled', 'disabled')
    }
  }
}

export default DownloadPoll
