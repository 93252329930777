import React from 'react'

const CartFormInput = props => (
  <input {...props} className={`input mb0 ${props.className}`} />
)

CartFormInput.defaultProps = {
  className: '',
}

export default CartFormInput
