import React from 'react'
import PropTypes from 'prop-types'
import paperGlobal from 'paper'
import { SignageInputs } from '@kennethormandy/cygnuscloud-editor'
import SignagePreviewProof from './SignagePreviewProof.jsx'

// Ours…
import { Sticky, StickyContainer } from './Sticky.jsx'
import CartFormLabel from './CartFormLabel.jsx'
import CartFormInput from './CartFormInput.jsx'
import CartFormButton from './CartFormButton.jsx'
import CartFormButtonRemove from './CartFormButtonRemove.jsx'

const CartFormRepeatableContainer = props => <div {...props} className="" />
const CartFormRepeatableItem = props => (
  <div {...props} className="flex items-center mb2" />
)
const CartFormRepeatableItemChild = props => <div {...props} className="mr1" />

const CustomButtonAdd = props => (
  <button
    {...props}
    className={`btn btn-small btn-outline col-12 ${props.className}`}
  />
)

const signageInputComponents = {
  Label: CartFormLabel,
  Input: CartFormInput,
  Button: CartFormButton,
  // ButtonRemove: CartFormButtonRemove,
  // RepeatableContainer: CartFormRepeatableContainer,
  // RepeatableItem: CartFormRepeatableItem,
  // RepeatableItemChild: CartFormRepeatableItemChild,
  ButtonAdd: CustomButtonAdd,
}

const CartItemEditor = props => {
  let itemProduct = props.product
  let inputPrefix = `${props.namePrefix}[${props.id}]`
  let optionsPrefix = `${inputPrefix}[options]`
  let inputNamePrefix = `${optionsPrefix}[content]`
  let Tag = props.as
  let specifications = props.specifications || itemProduct.specifications

  return (
    <Tag className="bg-white height-100">
      <input type="hidden" name={`${optionsPrefix}[client]`} value="[Client]" />
      <input
        type="hidden"
        name={`${optionsPrefix}[project]`}
        value="[Project]"
      />
      {specifications.map((spec, index) => {
        // Create Paper scope for each preview
        let paper = new paperGlobal.PaperScope()
        let content = props.content[index]
        let keyStr = `CartItemEditor_${spec.id}_{$index}`

        return (
          <div key={keyStr} className="md-flex md-min-height-100">
            <div className="bg-silver col-12 md-col-6 lg-col-8 relative md-min-height-100">
              <div className="md-sticky top-0 p2 col-12">
                <SignagePreviewProof
                  paper={paper}
                  sign={spec}
                  pictograms={props.pictogramOptions}
                  finishes={props.finishes}
                  content={content}
                  inset={spec.previewInset}
                  namePrefix={inputNamePrefix}
                  _validation={props._validation}
                  _validationRepeatables={props._validationRepeatables}
                  _validationOutputRef={props._validationOutputRef}
                />
              </div>
            </div>
            <div className="bg-white col-12 md-col-6 lg-col-4 md-min-height-100">
              <StickyContainer>
                <div className="bg-white p2 pb3 md-p3 pb4">
                  {props.childrenTop}
                  <div className="my2">
                    <h2 className="mt0 mb0 h2 line-height-2 magenta">
                      {spec.signType} {spec.title}
                    </h2>
                    <div>
                      {spec.width}×{spec.height}mm
                    </div>
                  </div>

                  <SignageInputs
                    components={signageInputComponents}
                    sign={spec}
                    namePrefix={inputNamePrefix}
                    content={content}
                    pictogramOptions={props.pictogramOptions}
                    onChange={props.onChangeInputs}
                    _validationRepeatables={props._validationRepeatables}
                  />
                </div>

                <div className="sticky bottom-0">
                  <div className="bg-white border-top border-silver p2 md-px3">
                    {props.childrenBeforeButton}

                    <button className="btn btn-primary btn-small">
                      {props.buttonLabel}
                    </button>

                    {props.childrenAfterButton}
                  </div>
                </div>
              </StickyContainer>
            </div>
          </div>
        )
      })}
    </Tag>
  )
}

CartItemEditor.defaultProps = {
  buttonLabel: 'Add to Order',
  as: 'div',

  // lineItems when editing the cart,
  // purchasables when adding new items to the cart [?]
  // https://docs.craftcms.com/commerce/v2/adding-to-and-updating-the-cart.html#adding-multiple-purchasables-to-the-cart
  namePrefix: 'lineItems',
  id: 0,
  _validationRepeatables: true,
}

CartItemEditor.propTypes = {
  childrenTop: PropTypes.node,
  childrenBeforeButton: PropTypes.node,
  childrenAfterButton: PropTypes.node,
  as: PropTypes.string.isRequired,
  namePrefix: PropTypes.oneOf(['lineItems', 'purchasables']).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pictogramOptions: PropTypes.object.isRequired,
  finishes: PropTypes.object.isRequired,
  onChangeInputs: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    })
  ),
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string,
    specifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      })
    ),
    snapshot: PropTypes.shape({
      productId: PropTypes.string,
      options: PropTypes.shape({
        content: PropTypes.object,
      }),
    }),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
}

export default CartItemEditor
