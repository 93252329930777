import formSerialize from 'form-serialize'
import isDev from './is-dev'

const craftAction = function(formNode, csrfToken, cb, cbError = null) {
  if (window && window.fetch) {
    let dataSerialized = formSerialize(formNode, { hash: false })

    // if (isDev()) {
    //   console.log('formNode', formNode)
    //   console.log('dataSerialized', dataSerialized)
    // }

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (csrfToken && typeof csrfToken !== 'undefined') {
      headers['X-CSRF-Token'] = csrfToken
    }

    window
      .fetch('', {
        method: 'POST',
        headers: new Headers(headers),
        body: dataSerialized,
      })
      .then(function(res) {
        if (res) {
          // Parses JSON response into native Javascript objects
          // Ex. if there was an error that came back from the form
          // Right now you’ll get a 404 because the backend URL doesn’t exit

          if (res.ok) {
            if (isDev()) {
              console.log('Success! Returned JSON from the server')
            }
          } else {
            console.warn(
              `Unsuccessful, returned ${res.status} status code`,
              res
            )

            if (typeof cbError === 'function') {
              return res.json().then(json => {
                return cbError(json)
              })
            }

          }

          if (cb && res.ok) {
            return res.json().then(json => {
              if (isDev()) {
                console.log(json)
              }

              json.success = typeof json.success !== 'undefined' ? json.success : res.ok
              json.statusText = typeof json.statusText !== 'undefined' ? json.statusText : res.statusText
              return cb(json)
            })
          }
        }

        return res
      })
      // .then(text => console.log(text))
      .catch(function(err) {
        console.warn('Error:', err)
      })
  }
}

export default craftAction
