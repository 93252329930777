import React from 'react'
import PropTypes from 'prop-types'
import {
  SignagePreview,
  SignageAssetsProvider,
  createDefaultContent,
} from '@kennethormandy/cygnuscloud-editor'

import paperGlobal from 'paper'

class SignagePreviewProof extends React.Component {
  constructor() {
    super()

    this.paper = new paperGlobal.PaperScope()
  }

  render() {
    const props = this.props

    // When empty, these seem to be incorrectly falling back to an array
    // instead of an object when coming from react_component in Twig.
    const pictograms = props.pictograms || {}
    const fontFamilies = props.fontFamilies || {}

    // All previews have a base amount of padding, defined here
    let defaultClassName = 'bg-silver select-none p2'

    // Creating default content is not done automatically by `<SignagePreview />`
    let content = props.content
    if (!props.content) {
      content = createDefaultContent(props.sign, {
        content: [],
        pictograms: pictograms,
      })
    }

    return (
      <div className={defaultClassName}>
        <SignageAssetsProvider
          {...props}
          pictograms={pictograms}
          paper={this.paper}
          render={({
            loadedPictograms,
            loadedFiles,
            loadedFonts,
            paperSymbols,
          }) => {
            // Same props provided to both SVG string, and canvas previews
            let renderProps = {
              loadedPictograms,
              loadedFiles,
              loadedFonts,
              paperSymbols,
            }

            return (
              <SignagePreview {...props} {...renderProps} pictograms={pictograms} fontFamilies={fontFamilies} content={content} paper={this.paper} />
            )
          }}
        />
      </div>
    )
  }
}

export default SignagePreviewProof
