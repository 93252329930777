import React from 'react'
import PropTypes from 'prop-types'

const CartEditorModalNav = props => {
  // Trying out keeping the page in the URL—for now that’s a distraction
  // <Link to={`/signs/${props.uid}/${pageNumberIndex + 1}`}>Next →</Link>

  let dots = []
  for (let i = 0; props.total - 1 >= i; i++) {
    dots.push(
      <div style={{ padding: '0 2px' }} key={`dot_${i}`}>
        <div
          className={`circle block border border-gray ${
            i === props.lineItemIndex ? 'bg-gray' : ''
          }`}
          style={{ width: '6px', height: '6px' }}></div>{' '}
      </div>
    )
  }

  return (
    <div>
      <div className="flex items-center col-12 justify-between">
        <div>
          <button
            className="btn btn-small btn-outline"
            disabled={!(props.lineItemIndex - 1 >= 0)}
            onClick={e => {
              if (e && typeof e.preventDefault !== 'undefined') {
                e.preventDefault()
              }
              if (props.onClickPrev) {
                return props.onClickPrev(props.lineItemIndex - 1)
              }
            }}>
            {props.labelPrev}
          </button>
        </div>
        <div>
          <div className="center">
            Item {props.lineItemIndex + 1} of {props.total}
          </div>
          <div className="flex justify-center">{dots}</div>
        </div>
        <div>
          <button
            className="btn btn-small btn-outline"
            disabled={!(props.total - 1 >= props.lineItemIndex + 1)}
            onClick={e => {
              if (e && typeof e.preventDefault !== 'undefined') {
                e.preventDefault()
              }
              if (props.onClickNext) {
                return props.onClickNext(props.lineItemIndex + 1)
              }
            }}>
            {props.labelNext}
          </button>
        </div>
      </div>
    </div>
  )
}

CartEditorModalNav.propTypes = {
  total: PropTypes.number.isRequired,
  lineItemIndex: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  labelNext: PropTypes.string.isRequired,
  labelPrev: PropTypes.string.isRequired,
}

CartEditorModalNav.defaultProps = {
  labelNext: 'Next →',
  labelPrev: '← Previous',
}

export default CartEditorModalNav
