/**
 * @returns {boolean}
 */
const isDev = function() {
  return (
    typeof process !== 'undefined' &&
    typeof process.env !== 'undefined' &&
    typeof process.env.NODE_ENV !== 'undefined' &&
    process.env.NODE_ENV === 'development'
  )
}

export default isDev
