import React from 'react'

const CartFormButton = props => (
  <button {...props} className={`btn ${props.className}`} />
)

CartFormButton.defaultProps = {
  className: '',
}

export default CartFormButton
