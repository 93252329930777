import React from 'react'

const CartFormLabel = props => (
  <label {...props} className={`block label mb2 ${props.className}`}>
    <span>{props.text}</span>
    {props.children}
  </label>
)

CartFormLabel.defaultProps = {
  className: '',
}

export default CartFormLabel
