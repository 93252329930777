import React from 'react'
import PropTypes from 'prop-types'

const StickyContainer = props => {
  return <div className="relative height-100" {...props} />
}

const Sticky = props => {
  let stickyClass = 'sticky'
  if (props.bp) {
    stickyClass = `${props.bp}-${stickyClass}`
  }
  return <div className={`top-0 z${props.z} ${stickyClass}`} {...props} />
}

Sticky.defaultProps = {
  z: 2,
}

Sticky.propTypes = {
  z: PropTypes.oneOf([1, 2, 3, 4]),
  bp: PropTypes.oneOf(['sm', 'md', 'lg']),
}

export { Sticky, StickyContainer }
