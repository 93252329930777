class PrimaryNav {
  constructor (el) {
    if (!el) {
      return
    }

    this.navItems = el.querySelectorAll('[data-primarynav-list] a')
    this.toggleButton = el.querySelector('[data-primarynav-button]')

    this._init()
  }

  _init() {

    // On initial load or after resizing to a small enough size,
    // collapse the menu, which is expanded by default in the HTML and CSS
    let initMenu = () => {
      // --breakpoint-md
      const _navMediaQueryMaxWidth = '52em'

      if (
        window.matchMedia &&
        window.matchMedia(`(max-width: ${_navMediaQueryMaxWidth})`).matches
      ) {
        closeMenu();
      }
    };

    let closeMenu = () => {
      this.toggleButton.setAttribute("aria-expanded", false);
    };

    let openMenu = () => {
      this.toggleButton.setAttribute("aria-expanded", true);
    };

    let toggleMenu = () => {
      let menuExpanded = this.toggleButton.getAttribute("aria-expanded");
      if (menuExpanded === "false" || !menuExpanded) {
        openMenu();
      } else {
        closeMenu();
      }
    };

    initMenu();
    this.toggleButton.addEventListener("click", toggleMenu);

    for (const item of this.navItems) {
      item.addEventListener("click", () => {
        // Auto-close menu after click?
        closeMenu();
      });
    }

    if (window) {
      window.addEventListener("resize", () => initMenu());
    }
  }
};

export default PrimaryNav
