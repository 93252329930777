import React from 'react'
import PropTypes from 'prop-types'
import craftAction from '../utils/craft-action'

class OrderReviewQty extends React.Component {
  constructor(props) {
    super(props)

    this.form = null

    this.state = {
      qty: props.qty,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleIncrement = this.handleIncrement.bind(this)
    this.handleDecrement = this.handleDecrement.bind(this)
    this.handleIncrementOrDecrement = this.handleIncrementOrDecrement.bind(this)
    this.handleChangeCallback = this.handleChangeCallback.bind(this)
  }

  handleChangeCallback() {
    craftAction(this.form, this.props.csrfToken)

    if (this.state.qty === 0) {
      let lineItemEl = document.querySelector(
        `[data-line-item-id="${this.props.id}"]`
      )
      lineItemEl.style.display = 'none'
    }
  }

  handleChange(e) {
    if (typeof e !== 'undefined' && e) {
      e.preventDefault()

      if (e.target && typeof e.target.value !== 'undefined') {
        let valueAsNumberOrBlank = parseInt(e.target.value, 10)
        if (
          isNaN(valueAsNumberOrBlank) === false &&
          (typeof valueAsNumberOrBlank === 'number' &&
            this.shouldUpdateQty(valueAsNumberOrBlank))
        ) {
          this.setState(
            { qty: valueAsNumberOrBlank },
            this.handleChangeCallback
          )
        }
      }
    }
  }

  /**
   * @param {number} val
   * @returns {boolean}
   */
  shouldUpdateQty(val) {
    return val > 0 || (val === 0 && this.hasConfirmed())
  }

  /**
   * @returns {boolean}
   */
  hasConfirmed() {
    return window.confirm('Remove from Order?')
  }

  handleIncrementOrDecrement(e, multiplier = 1) {
    e.preventDefault()
    let newQty = this.state.qty + multiplier

    if (this.shouldUpdateQty(newQty)) {
      this.setState({ qty: newQty }, this.handleChangeCallback)
    }
  }

  handleIncrement(e) {
    this.handleIncrementOrDecrement(e, 1)
  }

  handleDecrement(e) {
    this.handleIncrementOrDecrement(e, -1)
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  render() {
    const props = this.props
    let disabled = 0 >= this.state.qty
    let inputId = `qty-${this.props.id}`

    let iconMinus = (
      <div dangerouslySetInnerHTML={{ __html: this.props.iconMinus }} />
    )
    let iconPlus = (
      <div dangerouslySetInnerHTML={{ __html: this.props.iconPlus }} />
    )

    return (
      <div className="flex items-center tnum">
        <label className="hide pr2" htmlFor={inputId}>
          Quantity
        </label>
        <div>
          <button
            aria-label="decrease quantity"
            disabled={disabled}
            className="btn btn-invisible"
            onClick={this.handleDecrement}>
            {iconMinus}
          </button>
        </div>
        <div className="px1">
          <form
            method="POST"
            ref={el => (this.form = el)}
            onSubmit={this.handleSubmit}>
            <input
              type="hidden"
              name="action"
              value="commerce/cart/update-cart"
            />
            <input
              aria-label="Quantity"
              type="text"
              className="input mb0 center px0"
              style={{ minWidth: '2.5em', maxWidth: '4em' }}
              name={`lineItems[${props.id}][qty]`}
              value={this.state.qty}
              onChange={this.handleChange}
              inputMode="numeric"
              id={inputId}
            />
          </form>
        </div>
        <div>
          <button
            aria-label="increase quantity"
            disabled={disabled}
            className="btn btn-invisible"
            onClick={this.handleIncrement}>
            {iconPlus}
          </button>
        </div>
      </div>
    )
  }
}

OrderReviewQty.propTypes = {
  qty: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // SVG strings passed from Twig
  iconPlus: PropTypes.string,
  iconMinus: PropTypes.string,
}

export default OrderReviewQty
