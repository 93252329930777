import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import * as Sentry from "@sentry/react";

// Ours
import SignagePreviewProof from './SignagePreviewProof.jsx'
import CartEditorModal from './CartEditorModal.jsx'
import CsrfInput from './CsrfInput.jsx'
import craftAction from '../utils/craft-action.js'

/** @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#usage-with-routes--component */
const RoutesWithSentrySupport = Sentry.withSentryReactRouterV6Routing(Routes);

const portalRoot = document ? document.getElementById('js-image') : null

const IconEdit = props => {
  return (
    <svg
      className="icon"
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle className="icon-bg" cx="20" cy="20" r="20" fill="#F4F4F4" />
      <path
        className="icon-fg"
        d="M22.5322 13.8199L11.9998 24.4077L11.2186 28.7464L15.3331 27.706L26.3034 17.5515M22.5322 13.8199L25.832 10.5547L29.6032 14.2863L26.3034 17.5515M22.5322 13.8199L26.3034 17.5515"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Switcher = props => {
  if (
    !props.product ||
    !props.product.variants ||
    1 >= props.product.variants.length
  ) {
    return null
  }

  return (
    <div className="flex portal-options">
      {props.product.variants.map((variant, index) => {
        return (
          <label key={`content_radio_${index}`} className="block">
            <input
              data-index={index}
              checked={index === props.currentIndex}
              onChange={props.onChange}
              type="radio"
              name="purchasableId"
              value={variant.id}
              className="hide"
            />
            <div className="portal-option">
              <SignagePreviewProof
                sign={props.sign}
                pictograms={props.pictograms}
                finishes={props.finishes}
                parentInset={props.sign.previewInset}
                content={variant.content || props.content || null}
              />
              <div>{variant.title}</div>
            </div>
          </label>
        )
      })}
    </div>
  )
}

class VariantAddToOrderButton extends Component {
  constructor() {
    super()

    this.form = null
    this.handleUpdateCart = this.handleUpdateCart.bind(this)
  }

  handleUpdateCart(e) {
    const props = this.props

    if (e) {
      e.preventDefault()
    }

    craftAction(this.form, props.csrfToken, json => {
      if (json.success) {
        alert('Added to order!')

        if (window && window.location && props.successUrl) {
          window.location.href = props.successUrl
        }
      }
    })
  }

  render() {
    const props = this.props

    return (
      <form
        ref={el => (this.form = el)}
        method="POST"
        onSubmit={this.handleUpdateCart}>
        <input type="hidden" name="action" value="commerce/cart/update-cart" />
        <CsrfInput />
        <input type="hidden" name="purchasableId" value={props.variant.id} />
        <button className={props.buttonClassName}>Add to Order</button>
      </form>
    )
  }
}

/**
 * Whether or not to opt-out of validating repeatables, ie. is this BC Parks.
 * That is the only thing we are checking, and the intent would be to remove
 * this and `_validationRepeatables` in this codebase and the editor, if
 * and when we update the BC Parks artwork and existing orders to use the
 * new nested repeatable format.
 * @returns {boolean}
 */
const shouldValidateRepeatables = function() {
  let result = true

  if (window && window.location && window.location.hostname) {
    let hostnameSplit = window.location.hostname.split('.')
    if (hostnameSplit.length && hostnameSplit[0] === 'bcparks') {
      result = false
    }
  }

  return result
}

const PageCartEditorModal = function({ closePath, ...props }) {
  let location = useLocation()
  let navigate = useNavigate()

  return (
    <CartEditorModal
      {...props}
      location={location}
      _validationRepeatables={shouldValidateRepeatables()}
      onRequestClose={e => {
        if (e) {
          e.preventDefault(e)
        }
        let closePathPathname = closePath.split('?')[0]
        let closePathSearch = closePath.split('?')[1]

        navigate({
          pathname: closePathPathname,
          search: closePathSearch ? closePathSearch : '',
        })
      }}
    />
  )
}

class SignageCustomizeButton extends Component {
  constructor() {
    super()

    this.state = {
      variantIndex: 0,
    }

    this.handleOnChangeContentRadio = this.handleOnChangeContentRadio.bind(this)
  }

  handleOnChangeContentRadio(e) {
    if (e && e.target && e.target.value) {
      let variantIndex = parseInt(e.target.getAttribute('data-index'), 10)
      this.setState({
        variantIndex,
      })
    }
  }

  render() {
    const props = this.props
    let variantContent = null

    if (props.product && props.product.variants) {
      variantContent =
        props.product.variants[this.state.variantIndex].content || null
    }

    // Priority is:
    // Variant Content (ie. hard-coded content on a sign)
    // Spec Content (ex. repeatable sign where you have filled in more default content)
    // None, which will use `createDefaultContent` to get sample content in template
    const content = variantContent || props.content || null

    const signProductProps = {
      pictograms: props.pictograms,
      finishes: props.finishes,
      csrfToken: props.csrfToken,
    }

    let linkProps = {
      state: {
        product: props.product,
        qty: 1, // state.qty
        variantIndex: 0, // state.variantIndex
        lineItemIndex: props.lineItemIndex,
      },
    }
    
    // Original path is used as the base path, but close path can preserve query params for pagination
    // This URL construction is all pretty brittle, but it does work
    const basePath = `${props.basePath}/${props.slug}`
    let closePath = `/${props.slug}`
    let successPath = basePath
    if (typeof props.queryString !== 'undefined' && props.queryString !== '' && props.queryString[0] === '?') {
      closePath = `${closePath}${props.queryString}`
      successPath = `${basePath}${props.queryString}`
    }

    return (
      <div>
        {portalRoot
          ? createPortal(
              <div className="portal">
                <SignagePreviewProof
                  sign={props.sign}
                  pictograms={props.pictograms}
                  finishes={props.finishes}
                  parentInset={props.sign.previewInset}
                  content={content}
                />
                <Switcher
                  currentIndex={this.state.variantIndex}
                  onChange={this.handleOnChangeContentRadio}
                  {...props}
                  parentInset={props.sign.previewInset}
                />
              </div>,
              portalRoot
            )
          : null}
        <BrowserRouter basename={props.basePath}>
          {props.product ? (
            <React.Fragment>
              {!variantContent ? (
                <Link
                  className={props.buttonClassName}
                  to={`${props.slug}/customize`}
                  state={linkProps.state}>
                  {props.buttonShowIcon ? (
                    <div className="flex items-center">
                      <IconEdit />
                      <div className="pl1 line-height-3">
                        Customize Sign Content
                      </div>
                    </div>
                  ) : (
                    'Customize Sign Content'
                  )}
                </Link>
              ) : (
                <VariantAddToOrderButton
                  variant={props.product.variants[this.state.variantIndex]}
                  csrfToken={props.csrfToken}
                  buttonClassName={props.buttonClassName}
                  successUrl={successPath}
                />
              )}
            </React.Fragment>
          ) : null}

          {/* This router should be able to exist once globally, right? */}
          <RoutesWithSentrySupport>
            <Route
              path=":slug/customize"
              element={
                <PageCartEditorModal
                  lineItemIndex={props.lineItemIndex}
                  lineItems={props.lineItems}
                  product={props.product}
                  closePath={closePath}
                  successUrl={successPath}
                  {...signProductProps}
                />
              }
            />
          </RoutesWithSentrySupport>
        </BrowserRouter>
      </div>
    )
  }
}

SignageCustomizeButton.defaultProps = {
  basePath: '/signage/catalogue',
  buttonClassName: 'btn btn-primary btn-small',
  buttonShowIcon: false,
  product: null,
  queryString: '',
}

SignageCustomizeButton.propTypes = {
  sign: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.arrayOf(PropTypes.object),
  }),
  buttonClassName: PropTypes.string.isRequired,
  lineItemIndex: PropTypes.number,
  lineItems: PropTypes.array,
  basePath: PropTypes.string,
  uid: PropTypes.string,
  slug: PropTypes.string,
  product: PropTypes.object,
}

export default SignageCustomizeButton
