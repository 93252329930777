import React from 'react'
import PropTypes from 'prop-types'
import isDev from './../utils/is-dev'

const CsrfInput = props => {
  if (
    !window ||
    !(typeof window !== undefined) ||
    !window.csrfTokenName ||
    !window.csrfTokenValue
  ) {
    if (isDev()) {
      console.warn('Missing CSRF input values')
    }
    return null
  }

  return (
    <input
      type="hidden"
      name={window.csrfTokenName}
      value={window.csrfTokenValue}
    />
  )
}

export default CsrfInput
