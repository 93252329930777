import React from 'react'

const CartFormButtonRemove = props => (
  <button {...props} className={`btn btn-small btn-outline ${props.className}`}>
    Remove
  </button>
)

CartFormButtonRemove.defaultProps = {
  className: '',
}

export default CartFormButtonRemove
