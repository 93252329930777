class ImageToggle {
  constructor (el) {
    if (!el) {
      return
    }

    this.container = el
    this.toggles = el.querySelectorAll('[data-btngroup-imagetoggle] input[name="imagetoggle"]')

    this._handleOnChange = this._handleOnChange.bind(this)
    this._bindEventListeners()
  }

  _bindEventListeners() {
    for (const radio of this.toggles) {
      radio.addEventListener('change', this._handleOnChange)
    }
  }

  _handleOnChange(e) {
    if (e.target.checked) {
      this.container.dataset.imagetoggle = e.target.value
      // if (el.dataset.imagetoggleImage === e.target.value) {
      //   el.classList.remove(this.toggleClassName)
      // } else {
      //   el.classList.add(this.toggleClassName)
      // }
    }
  }
}

export default ImageToggle
