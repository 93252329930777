import React from 'react'
import PropTypes from 'prop-types'

// NOTE Could probably improve the props on this
// - Variant ID for the variant
// - ID for generating a new instanceKey when adding [?]
// - index for adding or editing multiple line items, where
// the count would be purchasables[0], purchasables[1], purchasables[2]
// https://docs.craftcms.com/commerce/v2/adding-to-and-updating-the-cart.html#options-uniqueness
// or lineItems[12], lineItems[44], lineItems[48], etc.
// updating a line item when editing
// Not the best prop names!
const SignProductPurchasableInputs = props => {
  // This key is using the date, to avoid the following problem:
  // if you add 3 signs, and then go back and add 5 more,
  // some of the IDs will conflict because they
  // are only unique in the context of one submission
  // let instanceKey = `${props.id}_${props.index}`
  let instanceKey = `${props.id}_${props.index}_${Date.now()}`
  if (props.instanceKey) {
    instanceKey = props.instanceKey
  }

  let namePrefix = `${props.inputNamePrefix}[${props.index}]`

  let inputType = 'hidden'
  let Tag = React.Fragment
  if (props.debug) {
    inputType = 'text'
    Tag = 'div'
  }

  return (
    <Tag>
      {props.debug ? <code>{namePrefix}</code> : null}
      {props.variantId ? (
        <input
          type={inputType}
          name={`${namePrefix}[id]`}
          value={props.variantId}
          readOnly
        />
      ) : null}
      <input type={inputType} name={`${namePrefix}[qty]`} value={1} readOnly />
      <input
        type={inputType}
        name={`${namePrefix}[options][instanceKey]`}
        value={instanceKey}
        readOnly
      />
    </Tag>
  )
}

SignProductPurchasableInputs.defaultProps = {
  debug: false,
  instanceKey: null,
  inputNamePrefix: 'purchasables',
}

SignProductPurchasableInputs.propTypes = {
  inputNamePrefix: PropTypes.oneOf(['purchasables', 'lineItems']).isRequired,
  debug: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
}

export default SignProductPurchasableInputs
