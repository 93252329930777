import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import bodyScroll from 'body-scroll-toggle'
import throttle from 'lodash/throttle'

// Not working
import { createDefaultContent } from '@kennethormandy/cygnuscloud-editor'

// Ours
import CsrfInput from './CsrfInput.jsx'
import CartItemEditor from './CartItemEditor.jsx'
import CartEditorModalNav from './CartEditorModalNav.jsx'
import SignProductPurchasableInputs from './SignProductPurchasableInputs.jsx'
import craftAction from './../utils/craft-action'
import isDev from './../utils/is-dev'

/**
 * Set the modal container. It seems to give an error if
 * this is left as the body, and not done explicitly. On
 * PDF views, this doesn’t exist, and isn’t an issue.
 * @returns {void}
 */
function initModalContainer() {
  try {
    Modal.setAppElement('#js-modal-container')
  } catch (error) {
    console.warn(error)
  }
}

initModalContainer()

const ButtonClose = props => (
  <div className="absolute top-0 right-0 z1">
    <button aria-label="Close" className="btn p1" onClick={props.onClick} disabled={props.disabled}>
      <svg
        className="icon"
        width="30"
        height="30"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle className="icon-bg" cx="25" cy="25" r="25" fill="#F4F4F4" />
        <path
          className="icon-fg"
          d="M16.667 33.3334L33.3337 16.6667M16.667 16.6667L33.3337 33.3334"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </div>
)

const CartEditorModalDefaultLocationState = {
  qty: 1,
  variantIndex: 0,
  product: {},
  lineItemIndex: 0,
}

class CartEditorModal extends React.Component {
  constructor(props) {
    super(props)

    // TODO 2022-07
    // This fixes deep merge of location state, so modal can be opened from anywhere via URL,
    // even if you haven’t clicked a router link to open it. The next step would probably be to
    // query the GraphQL API using something from the URL, if the product details are not
    // provided via the slug or UID or other info in the URL.
    this.locationState = Object.assign(
      {},
      CartEditorModalDefaultLocationState,
      props.location.state
    )
    if (
      typeof this.locationState.product === 'undefined' ||
      (!Object.keys(this.locationState.product).length && props.product)
    ) {
      this.locationState.product = props.product
    }

    this.state = {
      qty: this.locationState.qty,
      variantIndex: this.locationState.variantIndex,
      lineItemIndex: this.locationState.lineItemIndex || 0,
      lineItems: [],
    }

    // Using object, because this is quite similar to Cart.jsx
    // and might get refactored back into one
    this.state.lineItems = this.createStack(this.state)

    this.getUniquePurchasableInputs = this.getUniquePurchasableInputs.bind(this)

    this.handleUpdateCart = this.handleUpdateCart.bind(this)

    this.handleSaveCart = this.handleSaveCart.bind(this)
    this.handleSaveCart = throttle(this.handleSaveCart, 1000)
    this.handleOnRequestClose = this.handleOnRequestClose.bind(this)

    this.form = null
    this.timeoutHideShowSaved = null
    // Doing this with a callback ref for now
    // this.form = React.createRef()

    this.validationOutputRef = React.createRef()
  }

  componentDidMount() {
    bodyScroll.disable()
  }

  componentWillUnmount() {
    bodyScroll.enable()
  }

  createStack(state) {
    const props = this.props
    state = state || this.state

    if (!props.lineItems) {
      // No line items, so we are probably adding new items.
      // Create line items from quantity
      return this.createStackFromQty(state)
    }

    return this.createStackFromLineItems(state)
  }

  createStackItemFromLineItem(lineItem) {
    const props = this.props

    // Used to be lineItem.snapshot.product.specifications, but not sure those
    // get automatically passed along anymore? Or maybe we were doing some
    // magic in signs.php which is now lost? We should put this on the
    // snapshot instead, but still have to run it through handleSpecifications()
    let specifications = lineItem.specifications

    return Object.assign({}, lineItem, {
      specifications: specifications,
      content: specifications.map(spec => {
        // Set default content, merge object with existing content from cart
        return Object.assign(
          createDefaultContent(spec, {
            content: [],
            pictograms: props.pictograms,
          }),
          lineItem.snapshot.options.content
        )
      }),
    })
  }

  createStackFromLineItems(state) {
    const props = this.props
    state = state || this.state

    let stackItems = props.lineItems.map((lineItem, index) => {
      return this.createStackItemFromLineItem(lineItem)
    })

    return stackItems
  }

  createStackFromQty(state) {
    const props = this.props
    const qty = state ? state.qty : this.state.qty
    let stackItems = []

    if (this.locationState && this.locationState.product) {
      for (let itemId = 0; qty > itemId; itemId++) {
        let product = this.locationState.product

        // TODO This seems to be an issue in moving away from groups
        if (
          typeof product.children !== 'undefined' &&
          product.children.length
        ) {
          // We are actually in a group for some reason
          product = product.children[0]
        }

        // NOTE Technically you could have more than
        // one specification, and we do iterate over
        // specifications in some places, but they are
        // also limited to one in the backend right now.

        // Use item.id in Cart.jsx, but here that would always be the
        // same, because we don’t have a line item id, because we haven’t
        // actually added it to the cart yet.
        let spec = product.specifications[0]

        let defaultContent

        // If there is already content, we don’t need to bother
        if (state.lineItems[itemId] && state.lineItems[itemId].content) {
          defaultContent = state.lineItems[itemId].content[0]
        } else {
          defaultContent = createDefaultContent(spec, {
            content: [],
            pictograms: props.pictograms,
          })
        }

        stackItems.push({
          content: [defaultContent],
        })
      }
    }

    if (isDev()) {
      console.log('stackItems', stackItems)
    }

    return stackItems
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.qty !== this.state.qty) {
      let lineItemIndex = this.state.lineItemIndex + 1
      if (lineItemIndex > this.state.qty) {
        lineItemIndex = this.state.qty
      }
      this.setState({
        lineItems: this.createStack(this.state),
        lineItemIndex: lineItemIndex,
      })
    }
  }

  getProductData() {
    const props = this.props
    const state = this.state
    const locationState = this.locationState

    if (!locationState || !locationState.product) {
      return null
    }

    return locationState.product
  }

  getUniquePurchasableInputs() {
    const props = this.props
    const state = this.state
    let uniqueProductInputs = []
    let newStackItem = this.getProductData()
    let debug = false

    if (isDev()) {
      console.log('state.lineItems', state.lineItems)
    }

    state.lineItems.forEach((existingStackItem, countIndex) => {
      // If it already exists, we have an id, otherwise we use the index
      // to indicate it’s a new line item
      if (isDev()) {
        console.log('existingStackItem', existingStackItem)
      }

      let itemId =
        typeof existingStackItem.id !== 'undefined'
          ? existingStackItem.id
          : countIndex

      // This check is confusing, should be clearer
      // Maybe the whole component just needs a prop
      // for whether you are adding or editing, or at
      // least one function to determine it the same
      // way every time for a lineItem/potential lineItem
      let product
      if (typeof existingStackItem === 'object' && existingStackItem.snapshot) {
        product = existingStackItem
      } else {
        product = newStackItem
      }

      if (product) {
        let variantId
        let instanceKey = null
        let keyStrPurchasable = `SignProductPurchasableInputs_${itemId}_${countIndex}`

        if (product.variants) {
          variantId = product.variants[state.variantIndex].id
        } else {
          variantId = this.locationState.variantId
        }

        let purchasableProps
        if (
          product.snapshot &&
          product.snapshot.options &&
          product.snapshot.options.instanceKey
        ) {
          instanceKey = product.snapshot.options.instanceKey
          // Edit line item
          purchasableProps = {
            debug: debug,
            id: itemId,
            index: itemId,
            inputNamePrefix: 'lineItems',
            instanceKey: instanceKey,
            variantId: null,
          }
        } else {
          // Add line item
          purchasableProps = {
            debug: debug,
            id: itemId,
            index: countIndex,
            instanceKey: null,
            variantId: variantId,
          }
        }

        uniqueProductInputs.push(
          <SignProductPurchasableInputs
            key={keyStrPurchasable}
            {...purchasableProps}
          />
        )
      }
    })

    return uniqueProductInputs
  }

  handleSaveCart(e) {
    const props = this.props

    if (e) {
      e.preventDefault()
    }

    if (isDev()) {
      console.log('save cart')
    }

    craftAction(this.form, this.props.csrfToken, json => {
      if (json.success) {
        // If we are saving again so soon, clear the
        // timeout so the showSaved message persists
        clearTimeout(this.timeoutHideShowSaved)
        this.setState(
          {
            showSaved: true,
          },
          () => {
            this.timeoutHideShowSaved = setTimeout(() => {
              this.setState({
                showSaved: false,
              })
            }, 2000)
          }
        )

        if (props.onUpdateCart && typeof props.onUpdateCart === 'function') {
          if (isDev()) {
            console.log('json', json)
          }
          props.onUpdateCart()
        }
      }
    })
  }

  handleUpdateCart(e) {
    const props = this.props

    if (e) {
      e.preventDefault()
    }
    craftAction(this.form, this.props.csrfToken, json => {
      if (json.success) {
        if (!props.lineItem) {
          alert('Added to order!')
        }

        if (window && window.location && props.successUrl) {
          window.location.href = props.successUrl
        }

        if (props.onUpdateCart && typeof props.onUpdateCart === 'function') {
          props.onUpdateCart()
        }
      }
    })
  }

  handleOnRequestClose(e) {
    if (e) {
      e.preventDefault()
    }

    bodyScroll.enable()

    if (typeof this.props.onRequestClose === 'function') {
      this.props.onRequestClose()
    }
  }

  render() {
    const props = this.props
    const state = this.state

    const product = this.locationState.product
    const lineItems = state.lineItems
    let uniqueProductInputs = this.getUniquePurchasableInputs()

    // let pageNumberIndex = parseInt(props.index || 0, 10)
    let pageNumberZeroIndex = state.lineItemIndex

    let stackItems = []

    // Next/prev nav is supported, but we don’t curretly make use of it
    const nav =
      isDev && lineItems && lineItems.length > 1 ? (
        <div className="mt3" style={{ display: 'none' }}>
          <CartEditorModalNav
            lineItemIndex={state.lineItemIndex}
            total={lineItems.length}
            onClickPrev={newValue => {
              this.setState({ lineItemIndex: newValue })
            }}
            onClickNext={newValue => {
              this.setState({ lineItemIndex: newValue })
            }}
          />
        </div>
      ) : null

    const validationOutput = (
      <div ref={this.validationOutputRef} className="h5 line-height-3 max-width-1 mb2"></div>
    )

    lineItems.forEach((stackItem, countIndex) => {
      let itemId = stackItem.id || countIndex

      if (stackItem && typeof stackItem.content !== 'undefined') {
        let match = countIndex === pageNumberZeroIndex

        // Quick check that could be improved
        // If we have lineItems passed in, it’s probably coming
        // from /orders rather than the empty state we start with
        // when adding from /signs
        // Could also move this to an argument returned to
        // iterateOverItems callback, or do it based on instanceKey
        let isNewItem = !(props.lineItems && props.lineItems.length >= 1)

        stackItems.push(
          <li
            className={`height-100 block ${match ? '' : 'hide'}`}
            key={`${stackItem.uid}_${countIndex}`}>
            <CartItemEditor
              qty={state.qty}
              onChangeQty={null}
              product={product}
              specifications={stackItem.specifications || null}
              namePrefix={isNewItem ? 'purchasables' : 'lineItems'}
              id={itemId}
              pictogramOptions={props.pictograms}
              finishes={props.finishes}
              content={stackItem.content}
              buttonLabel={
                props.lineItems && props.lineItems.length >= 1
                  ? 'Update item'
                  : 'Add to Order'
              }
              onChangeInputs={newSpecContent => {
                // TODO Pictogram options doesn’t seem to auto-update
                // always, without explicitly pressing
                // the “Update cart in Craft CMS” button
                let newLineItems = lineItems.slice()

                // Content is a single-item array for some reason, maybe because of
                // what createDefaultContent returns?
                newLineItems[countIndex]['content'][0] = newSpecContent
                this.setState({
                  lineItems: newLineItems,
                })

                // Disabled save while editing, as it’s confusing with the Update button
                if (props.saveWhileEditing) {
                  // If it’s an existing item, update
                  if (!isNewItem) {
                    this.handleSaveCart()
                  }
                }
              }}
              childrenBeforeButton={validationOutput}
              childrenAfterButton={nav}
              _validation={true}
              _validationRepeatables={props._validationRepeatables}
              _validationOutputRef={this.validationOutputRef}
            />
          </li>
        )
      }
    })

    return (
      <Modal
        className="modal"
        overlayClassName="modal-overlay"
        isOpen
        onRequestClose={this.handleOnRequestClose}>
        {/* Hidden Quantity and next/prev stack support */}
        {/* Quantity intentionally outside form */}
        {/* <div className="bg-white">
          <div className="flex items-center mb1">
            {props.lineItems && props.lineItems.length >= 1 ? null : (
              <label className="block max-width-1 flex items-baseline mt2">
                <div className="mr1">Quantity</div>
                <input
                  type="number"
                  className="input mr1"
                  value={state.qty}
                  min={1}
                  onChange={e => {
                    console.log(e)
                    let newValue =
                      e.target.valueAsNumber || parseInt(e.target.value)
                    this.setState({
                      qty: newValue,
                    })
                  }}
                />
              </label>
            )}

            <button onClick={this.handleUpdateCart} className="btn btn-primary btn-small">
              {props.lineItems && props.lineItems.length >= 1
                ? 'Update item'
                : 'Add to Order'}
            </button>
          </div>
        </div> */}

        <ButtonClose onClick={this.handleOnRequestClose} disabled={state.status === 'submitting'} />

        <form
          ref={el => (this.form = el)}
          method="post"
          className="height-100"
          onSubmit={this.handleUpdateCart}>
          <input
            type="hidden"
            name="action"
            value="commerce/cart/update-cart"
          />
          <CsrfInput />

          <ul className="list-style-none m0 p0 block height-100">
            {stackItems}
          </ul>

          {uniqueProductInputs}
        </form>

        <div className="absolute top-0 left-0 p1 z4">
          <span
            className={`muted border p1 rounded ${
              state.showSaved ? '' : 'hide'
            }`}>
            saved
          </span>
        </div>
      </Modal>
    )
  }
}

CartEditorModal.defaultProps = {
  index: 0,
  location: CartEditorModalDefaultLocationState,
  _validationRepeatables: true,
}

const variantPropsCheck = (props, propName, componentName) => {
  if (
    !props &&
    !props.location &&
    !props.location.state.variantIndex &&
    !props.location.state.variantId
  ) {
    return new Error(
      `'${componentName}' requires a 'variantIndex' and 'variants' object, or a specific 'variantId'.`
    )
  }
}

CartEditorModal.propTypes = {
  lineItemIndex: PropTypes.number.isRequired,
  successUrl: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  uid: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  saveWhileEditing: PropTypes.bool.isRequired,

  lineItems: PropTypes.array,
  onUpdateCart: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      variantIndex: variantPropsCheck,
      variantId: variantPropsCheck,
      qty: PropTypes.number,
      product: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        uid: PropTypes.string,
        url: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
          })
        ),
      }),
    }).isRequired,
  }).isRequired,
}

CartEditorModal.defaultProps = {
  lineItemIndex: 0,
  location: {
    state: {
      variantIndex: 0,
    },
  },
  saveWhileEditing: false,
  _validationRepeatables: true,
}

export default CartEditorModal
