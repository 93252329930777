class Accordion {
  constructor(el) {
    if (!el) {
      return
    }

    this.buttons = el.querySelectorAll('[data-accordion-button]')

    for (const button of this.buttons) {
      button.addEventListener('click', this._handleOnClick)
    }
  }

  _handleOnClick(e) {
    const button = e.currentTarget
    const section = button.parentElement.nextElementSibling;
    const expanded = button.getAttribute('aria-expanded') === 'true' || false;
    button.setAttribute('aria-expanded', !expanded);
    // section.hidden = expanded;
    section.setAttribute('aria-hidden', expanded)
  }
}

export default Accordion
