import './css/index.css'
import 'core-js/stable'

import React from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import * as Sentry from '@sentry/react';
import CraftReact from 'react-on-rails'

// Ours
import SignagePreviewProof from './components/SignagePreviewProof.jsx'
import SignageCustomizeButton from './components/SignageCustomizeButton.jsx'
import OrderReviewQty from './components/OrderReviewQty.jsx'

import PrimaryNav from './lib/primarynav.js'
import ShuffleGrid from './lib/shufflegrid.js'
import ImageToggle from './lib/imagetoggle.js'
import Accordion from './lib/accordion.js'
import DownloadPoll from './lib/download-poll.js'

Sentry.init({
  dsn: 'https://012a2d26d6389dbd8c3e8dc9ff1e1336@o4506701197934592.ingest.sentry.io/4506701364264960',
  integrations: [

    /**
     * Support React Router v6
     * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
     */
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
    }),

    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),

    /**
     * Support Canvas recordings, for editor
     * @see https://docs.sentry.io/platforms/javascript/session-replay/#canvas-recording
     */
    Sentry.replayCanvasIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    // /^https:\/\/yourserver\.io\/api/
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

CraftReact.register({
  SignagePreview: SignagePreviewProof,
  SignageCustomizeButton: SignageCustomizeButton,
  OrderReviewQty,
})

if (typeof document !== 'undefined' && typeof window !== 'undefined') {

  new PrimaryNav(document.querySelector('[data-primarynav]'))

  document.addEventListener('DOMContentLoaded', () => {

    initFathomGoals()

    // Quick fix, run before shuffle grid
    if (typeof window.csrfTokenName !== 'undefined' && typeof window.csrfTokenValue !== 'undefined') {
      const specAddToCartButtons = document.querySelectorAll(`form[data-specification-itemaddtocart] input[name="${ window.csrfTokenName }"]`)
      handleSpecAddToCartButtons(specAddToCartButtons)
    }

    window.shuffleGrids = window.shuffleGrids || []

    const grids = document.querySelectorAll('.js-shuffle')
    const imageToggleContainers = document.querySelectorAll('[data-imagetoggle]')
    const accordions = document.querySelectorAll('[data-accordion]')
    const downloadPolls = document.querySelectorAll('[data-download-poll]')

    for (const grid of grids) {
      let gridInstance = new ShuffleGrid(grid)
      window.shuffleGrids.push(gridInstance)
    }

    for (const container of imageToggleContainers) {
      new ImageToggle(container)
    }

    for (const accordionEl of accordions) {
      new Accordion(accordionEl)
    }

    for (const downloadPollEl of downloadPolls) {
      new DownloadPoll(downloadPollEl)
    }

  })
}

/**
 * A quick fix to replace the CSRF value, and allow caching for these buttons.
 * We want to cache all of the catalogue page for now, and this is the only
 * place where CSRF tokens are used.
 * @param {NodeList} els 
 * @returns void
 */
const handleSpecAddToCartButtons = function (els) {
  for (let el of els) {
    if (el.value != window.csrfTokenValue) {
      el.value = window.csrfTokenValue
    }
  }
}

/**
 * Add simple Fathom goal tracking to anything with a 
 * `data-goal` attribute set on the HTML.
 * @returns {void}
 */
const initFathomGoals = function () {
  let goalElements = document.querySelectorAll('[data-goal]')
  
  Array.from(goalElements).forEach(function (el) {
    let goalName = el.dataset.goal
    console.log('Goal Name', goalName)
    if (goalName && typeof fathom !== 'undefined') {
      el.addEventListener('click', () => {
        fathom.trackEvent(goalName)
        console.log('Goal Tracked')  
      })
    }
  })
}
